import React from "react";

import { ParallaxBanner } from "react-scroll-parallax";
import RestaurantImage from "../images/restaurant.jpg";
import InfoCard from "../components/InfoCard";
import { Typography } from "@material-tailwind/react";
import Bacon from "../images/noun-bacon-6190660.svg";
import Hamburger from "../images/noun-hamburger-6980881.svg";
import { Element } from "react-scroll";
const Restaurant = () => {
  return (
    <>
      <ParallaxBanner
        layers={[
          { image: RestaurantImage, speed: -20 },
          {
            speed: -15,
          },
        ]}
        class="h-128"
      >
        <div className="absolute inset-0 sm:from-gray-900/75 flex items-center justify-center sm:bg-gradient-to-tr "></div>
      </ParallaxBanner>
      <Element name="restaurant" className="w-full">
        <div className="relative bg-lightGray border-t-2  border-lightBlue pt-8 xs:px-4 md:px-16">
          <div className="proxima600 text-darkBlue pb-4">Mary's Cafe</div>
          <div className="arizona xs:text-3xl text-darkBlue md:text-5xl">
            American favorites made to order
          </div>
          <div className="flex xs:justify-center md:justify-left pb-4 pt-4">
            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 pb-12 w-full">
              <InfoCard
                title="Breakfast (Open-11am)"
                text="Classic breakfast fare. Get your day on the lake started off right"
                image={Bacon}
                textColor="text-darkBlue"
              />

              <InfoCard
                title="Lunch (11am - Close)"
                text="American favorites made to order. Take a break from boating to fuel your family"
                image={Hamburger}
                textColor="text-darkBlue"
              />
            </div>
          </div>
          <div className="gap-4 w-full text-center">
            {/*<h4 className="proxima600 text-darkBlue pb-4">Fri | 9am-3pm</h4>*/}
            <h4 className="proxima600 text-darkBlue pb-8">
              Sat - Sun | 7:30am-3pm
            </h4>
          </div>
        </div>
      </Element>
    </>
  );
};

export default Restaurant;
