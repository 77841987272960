import React, { useState } from "react";
import InfoCard from "../components/InfoCard";
import BoatLaunch from "../images/noun-boat-launch-200-3B6BF9.svg";
import Rentals from "../images/noun-lake-6809448.svg";
import Cafe from "../images/noun-cafe-6974749.svg";
import Datepicker from "react-tailwindcss-datepicker";
import Service from "../images/noun-propeller-6549197.svg";
import { Typography } from "@material-tailwind/react";
import { Element } from "react-scroll";
import Wave from "react-wavify";
const Services = () => {
  
  return (
    <Element name="about">
      <div className="relative bg-darkBlue border-t-2 border-white xs:pt-8 md:pt-24 xs:px-4 md:px-16">
        <div className="proxima600 text-white text-base pb-4">
          Whatever floats your boat
        </div>
        <div className="arizona xs:text-3xl text-white md:text-5xl">
          A full-service marina on beautiful Twin Lakes, CT
        </div>
        <div className="flex xs:justify-center md:justify-left  md:pb-32 xs:py-8 md:pt-16">
          <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 md:pb-24 w-full">
            <InfoCard
              title="Sales and Service"
              text="A selection of new and used boats. Bait and Tackle. Master mechanics on site"
              image={Service}
              linkTo="salesandservice"
              textColor="text-white"
            />

            <InfoCard
              title="Boat Rentals"
              text="Enjoy a day on the lake, all equipment provided"
              image={Rentals}
              linkTo="rentals"
              textColor="text-white"
            />

            <InfoCard
              title="Mary's Cafe"
              text="Breakfast or lunch on our lakefront patio"
              image={Cafe}
              linkTo="restaurant"
              textColor="text-white"
            />

            <InfoCard
              title="Boat Launch"
              text="Free parking all day when you launch your boat with us"
              image={BoatLaunch}
              textColor="text-white"
            />
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Services;
